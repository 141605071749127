import blog9 from "../images/blog9.jpg"
import blog10 from "../images/blog10.jpg"
import blog11 from "../images/blog11.gif"
import blog12 from "../images/blog12.png"
import blog13 from "../images/blog13.png"
import blog14 from "../images/blog14.png"

const blog1 = "https://miro.medium.com/max/1400/1*__qZBavJIRiMQHJf6AlFig.png"
const blog2 = "https://miro.medium.com/max/1400/1*djXUTs-JWV3w2Gr0ZNJ2kQ.jpeg"
const blog3 = "https://miro.medium.com/max/1400/1*J0WDV6ub4ZMzJxNESkjsrA.jpeg"
const blog4 = "https://miro.medium.com/max/1400/1*biGXuTtmeKWAtzd6_QAK6Q.jpeg"
const blog5 = "https://miro.medium.com/max/1400/1*hPFJwDLsLng-AEFin8IAOg.jpeg"
const blog6 = "https://miro.medium.com/max/1400/1*OVvPD2RDLdpaijieATc52w.jpeg"
const blog7 = "https://miro.medium.com/max/1400/1*vgqmgxAUeiKbUFYmnrUpsw.jpeg"
const blog8 = "https://miro.medium.com/max/700/1*RyftmgpGjH1yx45GtvTb_A.jpeg"
const blog15 = "https://miro.medium.com/max/1400/1*I3w32_OQYW9hJRdyzGs0XA.jpeg"
const blog16 = "https://miro.medium.com/max/720/1*v5c_ajXuXsTKQgQyIfvqog.jpeg"
const blog17 = "https://miro.medium.com/max/1400/1*GBb3Reg5n4RxgH-3Q-mmZg.webp"
const blog18 = "https://miro.medium.com/max/1400/1*z_YMJAipDLqen7TTQSEBsA.webp"
const blog19 = "https://miro.medium.com/max/1400/1*nCcZ1AGdnKHX2RK0W3EgRQ.webp"
const blog20 = "https://miro.medium.com/max/1356/0*kxPYwfJmkXZ3iCWy.webp"
const blog21 = "https://miro.medium.com/v2/resize:fit:1400/format:webp/0*29tgDjLfcEIXjrhW.png"

const blogs = [
    {
        id: 21,
        image: blog21,
        title: 'Demystifying GraphQL: A Quick Introduction to GraphQL for Modern Web Development',
        link: 'https://ilolo.medium.com/demystifying-graphql-a-quick-introduction-to-graphql-for-modern-web-development-3b1a806ff19f',
        date : '13',
        month: 'Mar',
        year: '2023'

    },
    {
        id: 20,
        image: blog20,
        title: 'Understanding the MERN Stack for Full-Stack Development',
        link: 'https://ilolo.medium.com/understanding-the-mern-stack-for-full-stack-development-d8c6978b5ecb',
        date : '30',
        month: 'Jan',
        year: '2023'

    },
    {
        id: 19,
        image: blog19,
        title: 'Clean Code 101: Comments',
        link: 'https://ilolo.medium.com/clean-code-101-comments-be39b5f2cfd0',
        date : '29',
        month: 'Dec',
        year: '2022'

    },
    {
        id: 18,
        image: blog18,
        title: 'React.js Hook Study Guide',
        link: 'https://ilolo.medium.com/react-js-hook-study-guide-8386ad23f97b',
        date : '16',
        month: 'Dec',
        year: '2022'

    },
    {
        id: 17,
        image: blog17,
        title: 'Clean Code 101: Functions',
        link: 'https://ilolo.medium.com/clean-code-101-functions-304262531d8a',
        date : '9',
        month: 'Dec',
        year: '2022'

    },
    {
        id: 16,
        image: blog16,
        title: 'Clean Code 101: Test-Driven Development',
        link: 'https://ilolo.medium.com/clean-code-101-test-driven-development-b506f1cd80fb',
        date : '21',
        month: 'Nov',
        year: '2022'
    },
    {
        id: 15,
        image: blog15,
        title: 'Clean Code 101: Properly Naming our Variables and Functions',
        link: 'https://ilolo.medium.com/clean-code-101-properly-naming-our-variables-and-functions-8b3e4a22aeed',
        date : '7',
        month: 'Nov',
        year: '2022'
    },
    {
        id: 14,
        image: blog14,
        title: 'Utilizing LinkedIn for the Early-Career Software Engineer',
        link: 'https://ilolo.medium.com/utilizing-linkedin-for-the-early-career-software-engineer-95d4afffe14d',
        date : '4',
        month: 'May',
        year: '2022'
    },
    {
        id: 13,
        image: blog13,
        title: 'Getting Started With Three.js',
        link: 'https://betterprogramming.pub/getting-started-with-three-js-7a9031379847',
        date : '21',
        month: 'Apr',
        year: '2022'
    },
    {
        id: 12,
        image: blog12,
        title: 'Phase 5: Space City Sneakers',
        link: 'https://ilolo.medium.com/phase-5-space-city-sneakers-4aa0f0aeb599',
        date : '12',
        month: 'Apr',
        year: '2022'
    },
    {
        id: 11,
        image: blog11,
        title: 'How to Add a Dark Mode Button With ReactS',
        link: 'https://betterprogramming.pub/how-to-add-a-dark-mode-button-with-reactjs-ec03b4630cd1',
        date : '17',
        month: 'Mar',
        year: '2022'
    },
    {
        id: 10,
        image: blog10,
        title: 'Phase 4: We’re So Close',
        link: 'https://ilolo.medium.com/phase-4-were-so-close-c81d88abe899',
        date : '17',
        month: 'Mar',
        year: '2022'
    },
    {
        id: 9,
        image: blog9,
        title: 'Using Serializer With Your Ruby on Rails API',
        link: 'https://betterprogramming.pub/using-serializer-with-your-ruby-on-rails-api-e76795f4b3ad',
        date : '11',
        month: 'Mar',
        year: '2022'
    },
    {
        id: 8,
        image: blog8,
        title: 'Phase 3: Getting to Know Ruby',
        link: 'https://ilolo.medium.com/phase-3-getting-to-know-ruby-484b0fdd2b2c',
        date : '22',
        month: 'Feb',
        year: '2022'
    },
    {
        id: 7,
        image: blog7,
        title: 'What is Big O?',
        link: 'https://ilolo.medium.com/what-is-big-o-47a57719a7df',
        date : '14',
        month: 'Feb',
        year: '2022'
    },
    {
        id: 6,
        image: blog6,
        title: `Phase 2: Maybe React isn't that bad!`,
        link: 'https://ilolo.medium.com/phase-2-maybe-react-isnt-that-bad-d1806f747a49',
        date : '28',
        month: 'Jan',
        year: '2022'
    },
    {
        id: 5,
        image: blog5,
        title: 'React.js: Understanding the Effect Hook',
        link: 'https://ilolo.medium.com/react-js-understanding-the-effect-hook-e8a6cba21791',
        date : '22',
        month: 'Jan',
        year: '2022'
    },
    {
        id: 4,
        image: blog4,
        title: 'Declarative vs. Imperative Coding',
        link: 'https://ilolo.medium.com/declarative-vs-imperative-coding-17104ba22dcd',
        date : '14',
        month: 'Jan',
        year: '2022'
    },
    {
        id: 3,
        image: blog3,
        title: 'Phase 1: Project Week-COMPLETE!',
        link: 'https://ilolo.medium.com/phase-1-project-week-complete-3aed279c269b',
        date : '07',
        month: 'Jan',
        year: '2022'
    },
    {
        id: 2,
        image: blog2,
        title: 'Week One as a Software Engineering Student',
        link: 'https://ilolo.medium.com/week-one-as-a-software-engineering-student-6314e12af4cd',
        date : '28',
        month: 'Dec',
        year: '2021'
    },
    {
        id: 1,
        image: blog1,
        title: 'Getting to know me...',
        link: 'https://ilolo.medium.com/getting-to-know-me-d896e39874e',
        date : '28',
        month: 'Dec',
        year: '2021'
    }     
    
];

export default blogs;